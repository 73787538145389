import {useEffect, useState} from "react";
import {useParams} from "react-router";

const objectType = (obj) => Object.prototype.toString.call(obj).slice(8, -1);
const isDefined = (param) => typeof param !== "undefined";
const isUndefined = (param) => typeof param === "undefined";
const isFunction = (param) => typeof param === "function";
const isNumber = (param) => typeof param === "number" && !isNaN(param);
const isBoolean = (bool) => objectType(bool) === "Boolean";
const isString = (str) => objectType(str) === "String";
const isArray = (arr) => objectType(arr) === "Array";
const isObject = (arr) => objectType(arr) === "Object";
const isDate = (date) => objectType(date) === "Date";
const isValidDate = (date) => !isNaN(date.getTime());
function getUniqueId() {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	return "id-" + s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

/***
 * Scroll to top with animation
 */
function scrollToTop(){
	window.scrollTo({top: 0, behavior: 'smooth'});
}

/***
 * Get image source form the object
 * @param {{url: string}} image
 * @returns {*}
 */
function getImageSrc(image: string | { url?: string, icon?: { url: string } } | undefined): string | undefined {
	if (typeof image === "string") {
		return image;
	} else if (typeof image === "object" && image !== null) {
		if (!!image.url) {
			return image.url;
		} else if (!!image.icon) {
			return image.icon.url;
		}
	}
	return undefined
}

/***
 * checks is positano filial
 * @returns isPositano: boolean
 */
function useIsPositano() {
	const POSITANO = "2096";
	const {filialId=""} = useParams();
	const [isPositano, setIsPositano] = useState(false);
	useEffect(() => {
		const newState = filialId === POSITANO;
		if(isPositano !== newState) {
			setIsPositano(newState);
		}
	}, [filialId, isPositano]);

	return isPositano;
}

/***
 * returns logo by filial
 * @returns logo: string
 */
useFilialLogo.logo = "";
function useFilialLogo() {
	const {filialId=""} = useParams();
	if(useFilialLogo.logo) {
		return useFilialLogo.logo;
	}

	const POSITANO = "2096";
	const defaultLogo = "/images/logos/logo.svg";
	const filialLogo = `/images/logos/logo${filialId}.svg`;
	switch (filialId) {
		case POSITANO:
			useFilialLogo.logo = filialLogo; break;
		default:
			useFilialLogo.logo = defaultLogo; break;
	}

	return useFilialLogo.logo;
}

/***
 * checks image url
 * @param img
 * @param placeholder
 * @returns Promise
 */
function checkUrl(img: any, placeholder: string): Promise<string> {
	return new Promise(resolve => {
		const imgSrc = getImageSrc(img);
		if (imgSrc) {
			const img = new Image();
			img.src = imgSrc;
			img.onload = () => {
				resolve(imgSrc);
			};
			img.onerror = () => {
				resolve(placeholder);
			}
		} else {
			resolve(placeholder);
		}
	})
}
/***
 * changes bad image url on placeholder
 * @param imageUrl
 * @param _placeholder
 * @returns checkedUrl: string
 */
function useCheckImageUrl(imageUrl, _placeholder?: string) {
	const filialPlaceholder = "/images/placeholder.svg";
	const placeholder = _placeholder || filialPlaceholder;
	const [checkedUrl, setCheckedUrl] = useState<string>(placeholder);

	useEffect(() => {
		let isCancelled = false;
		checkUrl(imageUrl, placeholder)
			.then(resp => {
				!isCancelled && setCheckedUrl(resp)
			});
		return () => {
			isCancelled = true;
		}
		// eslint-disable-next-line
	}, [imageUrl, filialPlaceholder]);

	return checkedUrl;
}

/***
 * Get param from search query
 * @param param
 * @param query
 */
function getQueryParam(param: string, query: string) {
	return decodeURIComponent((query.split(param + '=')[1] || '').split('&')[0])
}

/***
 * Returns  word with correct lexical ending
 * @param value
 * @param options
 */
function getCorrectEnding(value: number, options: {one: string; two: string; few: string}): string {
	const lastTwo = +(value.toString().slice(-2));
	const lastOne = +(value.toString().slice(-1));
	if((lastTwo > 10 && lastTwo < 20) || lastOne === 0 || (lastOne > 4 && lastOne < 10)) {
		return options.few;
	} else if (lastOne > 1 && lastOne < 5) {
		return options.two;
	} else {
		return options.one
	}
}

export {
	getQueryParam,
	getCorrectEnding,
	isDate,
	isValidDate,
	isString,
	isArray,
	isBoolean,
	isNumber,
	isDefined,
	isObject,
	getUniqueId,
	isUndefined,
	scrollToTop,
	isFunction,
	useIsPositano,
	useFilialLogo,
	useCheckImageUrl,
	checkUrl,
}