import {getMenu} from "../services/ApiService";
import {FILIALS} from "../constants";
import {ACTION_TYPES} from "./enums";
import {CartItemType} from "../types";


const actions = {
	setLocation: (filialId: number | string, tableId: string, dispatch) => {
		filialId = typeof filialId === "string"? parseInt(filialId) : filialId;
		const filialName = FILIALS[filialId];

		if (!!filialName) {
			getMenu(filialId)
				.then((data) => {
					dispatch({
						type: ACTION_TYPES.init,
						payload: data
					})
				})
				.catch(e => {
					console.error(e);
				});

			return {
				type: ACTION_TYPES.setLocation,
				payload: {
					location: {
						filialName,
						tableId
					},
					filialId
				}
			}
		}
		return {
			type: "",
			payload: {}
		};
	},
	clearCart: () => {
		return {
			type: ACTION_TYPES.clearCart
		}
	},
	addLager: (lagerId: number|string) => {
		return {
			type: ACTION_TYPES.addLager,
			payload: lagerId
		}
	},
	removeCartLager: (lagerId: number) => {
		return {
			type: ACTION_TYPES.removeCartLager,
			payload: lagerId
		}
	},
	editCartLagerComment: (payload: {lagerId: number, comment: string}) => {
		return {
			type: ACTION_TYPES.editCartLagerComment,
			payload
		}
	},
	editCartComment: (comment: string) => {
		return {
			type: ACTION_TYPES.editCartComment,
			payload: comment
		}
	},
	editCartLager: (cartItem: CartItemType) => {
		return {
			type: ACTION_TYPES.editCartLager,
			payload: cartItem
		}
	}

};

export default actions;