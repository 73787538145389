import React, {FC, useCallback} from "react";
import InputMask from "react-input-mask";

import "./PhoneInput.styl"

type PhoneInputType = {
	phoneUpdate: ({phone: string, isValid: boolean}) => void;
	phone: string;
}

const phonePattern = /^\+38 \d{3} \d{3} \d{2} \d{2}/i;

const PhoneInput: FC<PhoneInputType> = ({phoneUpdate, phone}) => {

	const setPhoneNumber = useCallback((event) => {
		const value = event.target.value;
		phoneUpdate({phone: value, isValid: phonePattern.test(value)});
	}, [phoneUpdate]);

	return <div className="phone_input_holder">
		<InputMask
			mask="+38 999 999 99 99"
			maskChar={null}
			value={phone}
			onChange={setPhoneNumber}
			placeholder="Телефон"
		/>
	</div>
};

export default PhoneInput;