import React, {FunctionComponent} from 'react';

import {CartTotalProps} from "./types";
import Button from "../../UI/Button/Button";

import './CartTotal.styl';

const CartTotal: FunctionComponent<CartTotalProps> = ({total, createOrder, readyToSubmit}) => {

	return (
		<div className="cart-total">
			<div className="total-price">
				<p className="total-price-title">Разом:</p>
				<p className="total-price-value">{total} грн</p>
			</div>
			<div className="create-order-button">
				<Button primary disabled={!readyToSubmit} onClick={readyToSubmit? createOrder : ()=>{}}>Замовити</Button>
			</div>
		</div>
	)
};


export default CartTotal;