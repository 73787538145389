import "./Loader.styl";

import React, {FC} from "react";
import cn from "classnames";

type LoaderProps = {
	className?: string;
	theme?: "violet" | "green" | "cyan" | "olive" | "dark";
}

const Loader: FC<LoaderProps> = ({className, theme}) => {
		return (
			<div className={cn("loader", className, {[`loader--${theme}`]: theme})}/>
		)
};

export default Loader;