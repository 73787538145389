import React, {FC} from "react";
import {compose} from "recompose";

import WithContext from "../../components/HOCs/WithContext/WithContext";
import ListItem from "../../components/ListItem/ListItem";
import actions from "../../context/actions";

import {LayoutPageProps} from "../../types";
import {CategoryPageProps} from "./types"

import "./Category.styl";

const Category: FC<CategoryPageProps> = (props) => {
	const {match, categoriesLink, isLoading, onAdd} = props;
	const {categoryId, filialId, tableId} = match.params;
	if (isLoading) {
		return null;
	}
	const c = categoriesLink[categoryId];
	return (
	<>
		<h2>{c.name}</h2>
		<ul className="categories">
			{c.lagers.map(item => (
					<ListItem {...item} onAdd={onAdd} filialId={filialId} tableId={tableId} key={item.id} />
					))}
		</ul>
	</>
	)
};

export default compose<CategoryPageProps, LayoutPageProps>(
	WithContext(
		(state) => ({
			isLoading: state.isLoading,
			categoriesLink: state.categoriesLink
		}),
		dispatch => ({
			onAdd: (id) => {dispatch(actions.addLager(id))}
		})
	)
)(Category);