import {ACTION_TYPES} from "./enums";
import {
	addLagerToCart,
	editCartComment,
	editCartLager,
	editLagerComment,
	removeCart,
	removeCartLager,
	saveCart
} from "../services/AssistanceService";

export default function reducer(state, action) {
	switch (action.type) {
		case ACTION_TYPES.init:
		case ACTION_TYPES.setLocation:
			return {...state, ...action.payload};
		case ACTION_TYPES.removeCartLager:
			return {...state, cart: saveCart(removeCartLager(action.payload, state.cart))};
		case ACTION_TYPES.addLager:
			return {...state, cart: saveCart(addLagerToCart(action.payload, state.cart, state.lagersLink))};
		case ACTION_TYPES.editCartLager:
			return {...state, cart: saveCart(editCartLager(action.payload, state.cart))};
		case ACTION_TYPES.editCartLagerComment:
			return {...state, cart: saveCart(editLagerComment(action.payload, state.cart))};
		case ACTION_TYPES.editCartComment:
			return {...state, cart: saveCart(editCartComment(action.payload, state.cart))};
		case ACTION_TYPES.clearCart:
			return {...state, cart: removeCart()};
		default:
			return state;
	}
}
