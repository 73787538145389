import React, {FC} from 'react';
import {useParams} from "react-router";
import cn from "classnames";

import {ROUTE} from "../../../constants";
import {useIsPositano, useFilialLogo} from "../../../utils";
import {updateRouteParamWithValue} from "../../../services/RouteService";

import Link from "../../UI/LinkAll/LinkAll";

import './Logo.styl'


type LogoProps = {}

const Logo: FC<LogoProps> = (props) => {
			const {tableId="", filialId=""} = useParams();
			const logo = useFilialLogo();
			const isPositano = useIsPositano();

			return (
				<Link to={updateRouteParamWithValue(ROUTE.HOME, {filialId, tableId})}
							className={cn("logo", {"positano": isPositano})}>
					<img src={logo} alt="logo" />
				</Link>
			)
};

export default Logo;
