import React, {FC, useCallback, useState} from 'react';

import {CartCommentProps} from "./types";
import Button from "../../UI/Button/Button";

import Icon from "../../UI/Icon/Icon";
import {ICONS} from "../../UI/Icon/enums";

import "./CartComment.styl";


const CartComment: FC<CartCommentProps> = ({maxLength, comment, editComment, placeholder}) => {
	const [inputVisibility, setInputVisibility] = useState(false),
		[commentValue, setComment] = useState(comment);

	/***
	 * edit single lager comment
	 */
	const editItemComment = useCallback((e) => {
		const {value} = e.target;
		setComment(value);
		editComment(value);
		setInputVisibility(true);
	}, [editComment]);

	/***
	 * reset comment
	 */
	const resetComment = useCallback(() => {
		setComment("");
		editComment("");
	}, [editComment]);

	return (
		<div className="cart-comment">
			{comment && !inputVisibility ? (
					<div className="cart-comment-edit">
						<div className={!commentValue ? "cart-comment-input" : "cart-comment-input not-empty"}>
							<Button onClick={() => setInputVisibility(true)}>
								<Icon icon={ICONS.pencil} />
							</Button>
							<input className="cart-comment-value" readOnly  value={comment}/>
						</div>
					</div>
				)
				: (
					<div className="cart-comment-edit">
						<div className={!commentValue ? "cart-comment-input" : "cart-comment-input not-empty"}>
							<Button
								className={!commentValue ? "hidden" : "inline-row__button"}
								onClick={resetComment}
							>
								<Icon icon={ICONS.circleCross}/>
							</Button>
											<input type="text"
										 value={commentValue}
										 onChange={editItemComment}
										 placeholder={placeholder}
										 maxLength={maxLength}
							/>
						</div>
					</div>
				)
			}
		</div>
	)
};


export default CartComment;