import React, {FC, useState, useCallback, useEffect} from "react";
import {useHistory, useLocation} from "react-router";
import cn from "classnames";

import {SearchPanelProps} from "./types";
import {LagerType} from "../../types";

import {ICONS} from "../UI/Icon/enums";
import {ROUTE} from "../../constants";
import {getQueryParam, useCheckImageUrl} from "../../utils";
import {updateRouteParamWithValue} from "../../services/RouteService";

import Icon from "../UI/Icon/Icon";
import NoData from "../UI/NoData/NoData";
import Link from "../UI/Link";

import "./SearchPanel.styl";


function searchLagers(lagers: LagerType[], searchValue: string): LagerType[] {
	return lagers.filter(({name}) => name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
}

const ResultPreviewItem: FC<{product: LagerType, filialId: number, tableId: string}> = ({product, filialId, tableId}) => {
	const {id, name, img, price} = product;
	const imgSrc = useCheckImageUrl(img);
	const splitPrice = price.toString().split(".");
	return (
		<li>
			<Link className="result-li" to={updateRouteParamWithValue(ROUTE.DETAIL, {filialId, tableId, id})}>
				<div className="image"><img src={imgSrc} alt={name}/></div>
				<div className="title">{name}</div>
				<div className="price">{`${splitPrice[0]}.${splitPrice[1] || "00"} грн`}</div>
			</Link>
		</li>
	)
};

const SearchPanel:FC<SearchPanelProps> = ({onClose, filialId, tableId, lagers}) => {
	const [searchValue, setSearchValue] = useState("");
	const history = useHistory();
	const {search} = useLocation();

	useEffect(() => {
		const find = getQueryParam("find", search);
		setSearchValue(find || "");
	}, [setSearchValue, search]);

	const goToSearchPage = useCallback(() => {
		if (searchValue) {
			history.push(updateRouteParamWithValue(ROUTE.SEARCH, {filialId, tableId}) + `?find=${encodeURI(searchValue)}`);
			onClose()
		}
	}, [history, searchValue, filialId, tableId, onClose]);

	const handleChange = useCallback((e) => {
		const value = e.target.value || "";
		setSearchValue(value);
	}, [setSearchValue]);

	const handleKeyPress = useCallback((e) => {
		if (e.key === 'Enter') {
			goToSearchPage();
		}
	}, [goToSearchPage]);

	const resetValue = useCallback(() => {
		setSearchValue("");
	},[setSearchValue]);

	let searchResult: Array<LagerType> = [];
	if (searchValue.length > 2 && lagers && lagers.length) {
		searchResult = searchLagers(lagers, searchValue);
	}
	const isVisibleResultPreview = searchValue.length > 2;


	return(
		<div className="search-panel-wrap">
			<div className="search-field">
				<input
					value={searchValue}
					onChange={handleChange}
					onKeyPress={handleKeyPress}
					type="text"
					className="search-input"
					placeholder="Пошук"
					autoFocus/>

				<div className={cn("clear-btn", {"visible": searchValue})} onClick={resetValue}>
					<Icon icon={ICONS.cross}/>
				</div>
			</div>

			<div className="backdrop" onClick={onClose}/>

			{isVisibleResultPreview && <div className="result-preview-wrap">
				{searchResult.length
					? <ul className="result-list" onClick={onClose}>
							{searchResult.map(item =>
								<ResultPreviewItem key={item.id} product={item} filialId={filialId} tableId={tableId}/>
							)}
						</ul>
					: <NoData>
							На жаль, за такимим запитом товарів не знайдено
						</NoData>}
			</div>}
		</div>
	)
};

export default SearchPanel;