import "./NoData.styl";

import React, {FC} from "react";
import cn from "classnames";

type NoDataPropsType = {
	className?: string;
	withMarginTop?: boolean;
}

const NoData: FC<NoDataPropsType> = ({className, children, withMarginTop = false, ...props}) => {
	return (
		<div {...props} className={cn("no-data", className, {[`no-data--apart`]: withMarginTop})}>
			<div className="no-data__content">{children}</div>
		</div>
	)
};

export default NoData;