import axios from "axios";

import {ResMenu} from "./types";
import {CartType} from "../types";

import {parseMenuJSON, createOrder} from "./AssistanceService";
const URL_API = process.env.API || "https://foodcourt.silpo.ua/api/2.0/exec/OnlineOrders";

/***
 * Return filial by it filial id from API
 * @param filialId
 */
export function getMenu(filialId: number): Promise<any> {
	const rData = {
		"method": "GetMenu",
		"data": {
			"filialid":
			filialId
		}
	};
	return axios.post<ResMenu>(URL_API, rData, {
		transformResponse: (r: {data: ResMenu}) => {return r}})
		.then(({data}) => parseMenuJSON(data))
}

/***
 * Create and send order
 * @param filialId
 * @param tableId
 * @param orderId
 * @param cart
 * @param phone
 */
export function sendOrder(filialId: number, tableId: number | string,  orderId: number, cart: CartType, phone: string): Promise<any> {
	const rData = {
		"method": "SaveOrders",
		"data": createOrder(+filialId, +tableId, orderId, cart, phone)
	};
	return axios.post(URL_API, rData)
	.then((r) => {
		if (r.status === 200) {
			return true;
		} else {
			throw new Error("Network error");
		}
	})
}
