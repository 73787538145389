import React, {FC, useState, useCallback} from 'react';
import {withRouter} from "react-router";
import {compose} from "recompose";
import cn from "classnames";

import {HeaderProps, HeaderOutProps} from "./types";

import WithContext from "../../HOCs/WithContext/WithContext";
import {updateRouteParamWithValue} from "../../../services/RouteService";
import {ROUTE} from "../../../constants";

import Logo from "../../UI/Logo/Logo";
import Button from "../../UI/Button/Button";
import Icon from "../../UI/Icon/Icon";
import {ICONS} from "../../UI/Icon/enums";
import Link from "../../UI/LinkAll/LinkAll";
import SearchPanel from "../../SearchPanel/SearchPanel";

import './Header.styl';


const Header: FC<HeaderProps & HeaderOutProps> = (props) => {
		const {filialName, tableId, count, filialId, lagers, match, history} = props,
					isCartHeader = match.url.includes(ROUTE.CART);

		const [showSearchPanel, setShowSearchPanel] = useState<boolean>(false);
		const closeSearchPanel = useCallback(() => {
			setShowSearchPanel(false);
		}, [setShowSearchPanel]);
		const toggleSearchPanel = useCallback(() => {
			setShowSearchPanel(!showSearchPanel);
		}, [showSearchPanel, setShowSearchPanel]);

		if(isCartHeader){
			return (
				<div id="header">
					<div className="header-wrapper">
						<div className="header-left cart-menu">
							<Button className="cart-menu-button" onClick={history.goBack}>
								<Icon icon={ICONS.leftArrow}/><span>Назад</span>
							</Button>
							<h2 className="cart-menu-title">Кошик</h2>
						</div>
					</div>
				</div>
			)
		}

	return (
			<div id="header">
				<div className="header-wrapper">
					<div className="header-left">
						<Logo />
					</div>
					<div className="header-right">
						<div className={cn("search-btn", {"active": showSearchPanel})} onClick={toggleSearchPanel}>
							<Icon icon={ICONS.search}/>
						</div>
						<div className="tooltip">
							<Icon icon={ICONS.markerOutline}/>
							<div className="tooltip-data">
								{filialName && <p className="store">{filialName}</p>}
								{tableId && <p className="table">столик {tableId}</p>}
							</div>
						</div>
						<Link to={updateRouteParamWithValue(ROUTE.CART, {
							filialId: filialId || "", tableId: tableId || ""
						})}>
							<Icon icon={ICONS.basket}/>
							{count > 0 && <sup className="menu-header_button-number">
								<span className="menu-header_button-value">{count}</span>
							</sup>}
						</Link>
					</div>
				</div>
				{showSearchPanel && <SearchPanel onClose={closeSearchPanel} filialId={filialId} tableId={tableId} lagers={lagers}/>}
			</div>
		)
	};

export default compose<HeaderProps, HeaderOutProps>(
	withRouter,
	WithContext(
		(state) => ({
			filialName: state.location.filialName,
			tableId: state.location.tableId,
			filialId: state.filialId,
			lagers: state.lagers,
			count: state.cart.count
		})
	)
)(Header);