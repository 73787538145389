import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {ROUT_LINKS, ROUTE} from './constants';

import * as serviceWorker from "./serviceWorker";
import {StoreProvider} from "./providers/storeProvider";
import Unavailable from "./pages/Unavailable/Unavailable";
import NotFound from "./pages/NotFound/NotFound";
import Home from "./pages/Home/Home";
import Category from "./pages/Category/Category";
import Cart from "./pages/Cart/Cart";
import Detail from "./pages/Detail/Detail";
import Search from "./pages/Search/Search";

import Layout from "./components/Layout/Layout";

import "./index.styl";


ReactDOM.render(
	<StoreProvider>
		<Router>
			<Switch>
				<Layout exact path={ROUT_LINKS[ROUTE.HOME]} component={Home}/>
				<Layout exact path={ROUT_LINKS[ROUTE.CATEGORY]} component={Category}/>
				<Layout exact path={ROUT_LINKS[ROUTE.CART]} component={Cart}/>
				<Layout exact path={ROUT_LINKS[ROUTE.DETAIL]} component={Detail}/>
				<Layout exact path={ROUT_LINKS[ROUTE.SEARCH]} component={Search}/>
				<Route path={ROUT_LINKS[ROUTE.UNAVAILABLE]} component={Unavailable}/>
				<Route path={ROUT_LINKS[ROUTE.NOT_FOUND]} component={Unavailable}/>
				<Route path="*" component={NotFound}/>
			</Switch>
		</Router>
	</StoreProvider>, document.getElementById("root"));


serviceWorker.unregister();
