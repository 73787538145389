export enum ICONS {
	accountDots = "icon-account-dots",
	aimbulance= "icon-aimbulance",
	appleShape= "icon-apple-shape",
	arrowRight= "icon-arrow-right",
	assistantHeadphones="icon-assistant-headphones",
	breadCrumbsArrow= "icon-bread-crumbs-arrow",
	basket= "icon-basket",
	calendar= "icon-calendar",
	caret= "icon-caret",
	card= "icon-card",
	credit= "icon-credit-card",
	cartBlockBasket= "icon-cart-block-basket",
	cartBlockOrder= "icon-cart-block-order",
	check= "icon-check",
	checkbox= "icon-checkbox",
	chevronDown= "icon-chevron-down",
	chevronLeft= "icon-chevron-left",
	chevronRight= "icon-chevron-right",
	circleCheck= "icon-circle-check",
	circleCross= "icon-circle-cross",
	circlePlus= "icon-circle-plus",
	circleSlash= "icon-circle-slash",
	comments="icon-comments",
	clock= "icon-clock",
	clockThin= "icon-clock-thin",
	cross= "icon-cross",
	crossThin= "icon-cross-thin",
	cup= "icon-cup",
	delete= "icon-delete",
	download= "icon-download",
	exit= "icon-exit",
	facebook= "icon-facebook",
	facebookCircle= "icon-facebook-circle",
	faqArrow= "icon-faq-arrow",
	faqThumbDown= "icon-faq-thumb-down",
	faqThumbUp= "icon-faq-thumb-up",
	fb= "icon-fb",
	file= "icon-file",
	filterList= "icon-filter-list",
	filterMap= "icon-filter-map",
	filters= "icon-filters",
	forKids= "icon-for-kids",
	instagram="icon-instagram",
	invoice= "icon-invoice",
	kidsTrademark= "icon-kids-trademark",
	leftArrow= "icon-left-arrow",
	mail= "icon-mail",
	marker= "icon-marker",
	marker_2= "icon-marker-2",
	markerOutline= "icon-marker-outline",
	menu= "icon-menu",
	more= "icon-more",
	pencil= "icon-pencil",
	phone= "icon-phone",
	press= "icon-press",
	priceCut= "icon-price-cut",
	print= "icon-print",
	productPlacement= "icon-product-placement",
	productPlacementNo= "icon-product-placement-no",
	productPlacementWarning= "icon-product-placement-warning",
	rightArrow= "icon-right-arrow",
	search= "icon-search",
	selectArrow= "icon-select-arrow",
	smallCalendar= "icon-small-calendar",
	star= "icon-star",
	storeClock= "icon-store-clock",
	storeMan= "icon-store-man",
	storeMarker= "icon-store-marker",
	telegramCircle= "icon-telegram-circle",
	ticket= "icon-ticket",
	trashBucket= "icon-trash-bucket",
	tw= "icon-tw",
	twitter= "icon-twitter",
	upload= "icon-upload",
	userAccount= "icon-user-account",
	viber="icon-viber",
	restore= "icon-restore",
	lockOpened= "icon-lock-opened",
	lockClosed= "icon-lock-closed",
	emailHotLine= "icon-email-hot-line",
	moneyCoins= "icon-money-coins",
	bus= "icon-bus",
	disability= "icon-disability",
	phoneEmpty= "icon-phone-empty",
	youtubeLogo= "icon-youtube-logo",
	note= "icon-note",
	timerClock= "icon-timer-clock",
	portfolio= "icon-portfolio",
	iconProposalMap= "icon-proposal-map",
	faq= "icon-faq",
	facebookCircleFora= "icon-facebook-circle-fora",
	instagramFora="icon-instagram-fora",
	telegramCircleFora= "icon-telegram-circle-fora",
	viberFora="icon-viber-fora",
}


export enum SHAPE  {
	circle= "circle",
}

export enum THEME {
	brown= "brown",
	orange= "orange",
	violet= "violet",
	cyan= "cyan",
	green= "green",
	ltgreen= "ltgreen",
	olive= "olive",
}