import React, {FunctionComponent} from 'react';
import cn from "classnames";

import {IntroProps} from "./types";
import {useIsPositano} from "../../utils";

import './Intro.styl';


const Intro: FunctionComponent<IntroProps>  = (props) => {
	const isPositano = useIsPositano();

	return (
		<>
			{isPositano && <div className="intro-positano-flag"/>}
			<div className={cn("intro", {"positano": isPositano})}>
				<p className="title">{props.title}</p>
				<p className="description">{props.description}</p>
			</div>
		</>
	)
};


export default Intro;