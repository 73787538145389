export enum ROUTE {
	ROOT = "root",
	CART = "cart",
	HOME = "home",
	SEARCH = "search",
	DETAIL = "detail",
	CATEGORY = "category",
	NOT_FOUND = "notFound",
	UNAVAILABLE = "unavailable",
	OTHER = "other",
}

export const PATHS = {
	[ROUTE.ROOT]: "/",
	[ROUTE.HOME]: "home",
	[ROUTE.SEARCH]: "search",
	[ROUTE.CATEGORY]: "category",
	[ROUTE.DETAIL]: "detail",
	[ROUTE.CART]: "cart",
	[ROUTE.NOT_FOUND]: "not-found",
	[ROUTE.UNAVAILABLE]: "unavailable",
	[ROUTE.OTHER]: "*",
};

export const ROUT_LINKS = {
	[ROUTE.ROOT]: `${PATHS[ROUTE.ROOT]}`,
	[ROUTE.OTHER]: `${PATHS[ROUTE.OTHER]}`,
	[ROUTE.HOME]: `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.HOME]}/:filialId/:tableId`,
	[ROUTE.SEARCH]: `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.SEARCH]}/:filialId/:tableId`,
	[ROUTE.DETAIL]: `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.DETAIL]}/:filialId/:tableId/:id`,
	[ROUTE.CATEGORY]: `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.CATEGORY]}/:filialId/:tableId/:categoryId`,
	[ROUTE.CART]: `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.CART]}/:filialId/:tableId`,
	[ROUTE.UNAVAILABLE]: `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.UNAVAILABLE]}`,
	[ROUTE.NOT_FOUND]: `${PATHS[ROUTE.ROOT]}${PATHS[ROUTE.NOT_FOUND]}`,
};


export const ENUM = {
	ALL: "ALL",
	YES: "YES",
	NO: "NO",

	// size
	NORMAL: "NORMAL",
	LARGE: "LARGE",
	FULL_SIZE: "FULL_SIZE",

	// themes colors
	WHITE: "WHITE",
	VIOLET: "VIOLET",
	CYAN: "CYAN",
	GREEN: "GREEN",
	OLIVE: "OLIVE",
	ORANGE: "ORANGE",

};

export const FILIALS = {
	1932: "«Кафе» на вул. Берковецька, 6д м. Київ",
	1933: "«Кафе» на вул. Кульпарківська, 226 А м. Львів",
	2065: "«Кафе» на пр. Павла Тичини 1В, м. Київ",
	2067: "«Кафе» на вул. Басейна, 6 м. Київ",
	2094: "«Кафе» на вул. Полярна, 20Д м. Київ",
	2096: "«Кафе» на вул. Павла Тичини, 1в м. Київ",
	2097: "«Кафе» на вул. Київська, 241 м. Бровари",
	2110: "«Кафе» на вул. Під Дубом, 7Б м. Львів",
	2207: "«Кафе» на Єкатиринославський бульвар, 1 м. Дміпро",
	2350: "«Сільпо» на вул. C.Стрільців, 37/41 м. Київ",
	2357: "«Кафе» на вул. Генуезька, 24Б м. Одеса",
	2666: "«Кафе» на пр-ті. Степана Бандери, 36 м. Київ",
	2668: "«Кафе» на вул. Дніпровська наб., 12 м. Київ",
	2744: "«Кафе» на вул. Київська, 10 с. Стоянка",
	2749: "«Кафе» на вул. Андрія Фабра, 7 м. Дміпро",
	2947: "«Кафе» на вул. Ярославська, 56А м. Київ",
	3186: "«Кафе» на вул. Харківське шосе, 1В с. Щасливе",
	3396: "«Кафе» на вул. Велика Окружна, 1, м. Київ",
	3445: "«ПАБ» на вул. Велика Окружна, 1, м. Київ",
	2051: "«Кафе» на вул. Архитектора Вербицкого, 1, м. Київ",
};

export const PAGE_TITLES = {
	main: "food court",
	cart: "Кошик"
};