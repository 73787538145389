import React, {FC} from "react";
import {compose} from "recompose";

import LogoSvg from "../../components/LogoSvg/LogoSvg";
import WithContext from "../../components/HOCs/WithContext/WithContext";
import {ROUTE} from "../../constants";
import Link from "../../components/UI/Link";
import {updateRouteParamWithValue} from "../../services/RouteService";

import "./NotFound.styl";

type NotFoundProps = {
	filialId: number;
	tableId: string;
}

const NotFound: FC<NotFoundProps> = (props) => {
	const {filialId, tableId}= props;

	return (
		<div className="error-page-wrapper">
			<div className="error-page-holder">
				<LogoSvg />
				<div className="error-code">404</div>
				<div className="error-text">
					Тут немає інформації, яку ви шукаєте.
					Будь ласка, перевірте адресу сторінки або почніть
					з головної — там ви швидко зорієнтуєтесь.
				</div>
				{!!filialId && !!tableId &&
					<Link className="home-button" to={updateRouteParamWithValue(ROUTE.HOME, {filialId, tableId})}>
							На головну
					</Link>}
			</div>
		</div>
	)
};

export default compose<NotFoundProps, {}>(
	WithContext(
		(state) => ({
			tableId: state.location.tableId,
			filialId: state.filialId,
		})
	)
)(NotFound);