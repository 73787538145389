import React, {FC, useEffect, useState, memo} from 'react';
import {Route, withRouter} from "react-router-dom";
import {compose} from "recompose";

import {LayoutOutProps, LayoutProps} from "./types";

import {scrollToTop} from "../../utils";
import WithContext from "../HOCs/WithContext/WithContext";
import actions from "../../context/actions";
import {PAGE_TITLES, ROUTE} from "../../constants";
import {ICONS} from "../UI/Icon/enums";

import Icon from "../UI/Icon/Icon";
import Header from "./Header/Header";
import Menu from "./Menu/Menu";

import './Layout.styl'


const scrollHeight = 200;

	const Layout: FC<LayoutProps> = memo((props) => {
		const {component: Component, location, ...rest} = props;
		const {computedMatch, updateLocation, isLoading} = props;
		const {filialId, tableId} = computedMatch.params;
		const [goTop, setGoTop] = useState(false);
		const isCartPage = location.pathname.includes(ROUTE.CART);

		useEffect(() => {
			if(isCartPage){
				document.title = PAGE_TITLES.cart;
			} else {
				document.title = PAGE_TITLES.main;
			}
		},[isCartPage]);

		useEffect(() => {
			isLoading && updateLocation(filialId, tableId);
		}, [isLoading, filialId, tableId, updateLocation]);

		useEffect(() => {
			const onScroll = () => {
				const scrolled = window.pageYOffset;
				if (scrolled > scrollHeight && !goTop) {
					setGoTop(true);
				} else if (scrolled <= scrollHeight && goTop) {
					setGoTop(false);
				}
			};
			window.addEventListener("scroll", onScroll);
			return () => {
				window.removeEventListener("scroll", onScroll);
			}
		}, [goTop]);

		const buttonClass = `back-to-top ${goTop ? "show" : ""}`;

		return (
			<Route {...rest} render={matchProps => (
				<div className={`layout`}>
					<Header/>
					<div className="container">
						<div className="content">
							<Component {...matchProps} />
						</div>
						<div className={buttonClass} onClick={scrollToTop}>
							<Icon icon={ICONS.arrowRight}/>
						</div>
					</div>
					<Menu />
				</div>
			)}/>
		)
	}, ((prevProps, nextProps) => {
		return (prevProps.location.pathname === nextProps.location.pathname);
	}));


export default compose<LayoutProps, LayoutOutProps>(
	withRouter,
	WithContext(
		(state) => ({
			filialName: state.location.filialName,
			tableId: state.location.tableId,
			isLoading: state.isLoading
		}),
		dispatch => ({
			updateLocation: (filialId, tableId) => dispatch(actions.setLocation(filialId, tableId, dispatch))
		})
	)
)(Layout);
