import React, {FC, memo, useCallback, useEffect, useRef, useState} from 'react';

import {DetailItemProps} from "./types";
import {ModifiersType, OtherLagerItemType} from "../../types";
import {useCheckImageUrl} from "../../utils";
import {ICONS} from "../UI/Icon/enums";

import Icon from "../UI/Icon/Icon";

import './DetailItem.styl';

const LIST_TYPE = {
	options: "Опції",
	ingredients: "Інгредієнти",
	nutritional: "Харчова цінність (100 г)",
	energy: "Енергетична цінність (100 г)"
};

/***
 * returns node of price separated by "."
 * @param price
 */
function renderPortionPrice(price: number) {
	const formattedPrice = price.toString().split(".");
	return (
		<div className="portion-price">
			<div className="portion-price-integer">{formattedPrice[0]}</div>
			<div className="portion-price-details">
				<div className="portion-price-fraction">{formattedPrice[1] || "00"}</div>
				<div className="portion-price-currency">грн</div>
			</div>
		</div>
	)
}


/***
 * render info lists
 * @param data
 * @param title
 * @param dotted
 */
function renderDetails(data: string[], title: string, dotted: boolean = false) {
	if (data && data.length > 0) {
		return (
			<div className="product-info-list-wrapper">
				<h4 className="product-info-list-title">{title}</h4>
				<ul className={`${dotted ? "product-info-list circle" : "product-info-list"}`}>
					{
						data.map((item) => {
							return (<li className="product-info-list-item" key={`product-info-list-item-${item}`}>{item}</li>)
						})
					}
				</ul>
			</div>
		)
	}
	return null
}/***
 * render all others item
 * @param data
 */
function renderOthers(data: OtherLagerItemType[]) {
	if (data && data.length > 0) {
		return (data.map((item) => { return (
			<div className="product-info-list-wrapper" key={item.title}>
				<h4 className="product-info-list-title">{item.title}</h4>
				<div className="product-info-list product-info-list-item">{item.value}</div>
			</div>)}
		))
	}
	return null
}

/***
 * Render modifiers/option items
 * @param options
 * @param title
 * @param active
 * @param onClick
 */
function renderOption(options: ModifiersType[], title: string, active: string[], onClick) {
	if (options && (options || []).length > 0) {
		return (
			<div className="product-info-list-wrapper">
				<h4 className="product-info-list-title">{title}</h4>
				<ul className="product-info-list">
					{
						options.map((item) => {
							return (
								<li onClick={onClick}
										className={`product-info-list-button ${
											active.indexOf(item.modifierName) !== -1? "product-info-list-button__active" : ""}`}
										key={`product-info-list-item-${item.modifierId}`}
								>{item.modifierName}</li>
							)
						})
					}
				</ul>
			</div>
		)
	}
	return null
}

const DetailItem: FC<DetailItemProps> = memo((props) => {
	const {id, img, price, unit, name, energy, nutrition, modifiers, composition, onAdd, others,
		cookingTime = "0:0"} = props;
	const imgSrc = useCheckImageUrl(img);
	const [activeModifier, setActiveModifier] = useState<string[]>([]);
	const productRef = useRef<HTMLImageElement>(null);
	const [minutes, setMinutes] = useState<number>(0);
	// const {flyingImage, animatedAddToCart} = FlyToCart(imgSrc, productRef);
	useEffect(() => {
		const [tmpH,tmpM] = cookingTime.split(":");
		const h = +(tmpH || 0) * 60;
		const m = +(tmpM || 0);
		setMinutes(h + m);
	}, [cookingTime]);

	const onClickItem = useCallback((event) => {
		const value = event.target.textContent;
		setActiveModifier(oldList => {
			const position = oldList.indexOf(value);
			const result: string[] = [];
			for (let i = 0; i < oldList.length; i++) {
				const item = oldList[i];
				if (i === position) {
					continue;
				}
				result.push(item);
			}
			if(position === -1) {
				result.push(value);
			}
			return result;
		})
	}, []);

	const addWithModifiers = useCallback(() => {
		// animatedAddToCart();
		if (activeModifier.length === 0) {
			onAdd(id);
		} else {
			const tmp: number[] = [id];
				for (const modifier of modifiers) {
					if (activeModifier.indexOf(modifier.modifierName) !== -1) {
						tmp.push(modifier.modifierId);
					}
				}
			onAdd(tmp.join("|"));
		}
	}, [activeModifier, id, modifiers, onAdd]);

	return (
		<div className="item-wrapper">
			{/*{flyingImage}*/}
			<img src={imgSrc} alt={name} className="image"/>
			<div className="cont">
				<div className="portion-title">
					<div className="title-text">{name}</div>
					{!!cookingTime && <div className="remind-time">
						<Icon icon={ICONS.clock}/>
						<span className="remind-time-text">≈ {minutes} хв</span>
					</div>}
					<div className="weight">{unit}</div>
				</div>
				<div className="info" ref={productRef}>
					{renderPortionPrice(price)}
					<div className="detail-basket-button" onClick={addWithModifiers}> В КОШИК </div>
				</div>

				<div className="product-info">
					{renderOption(modifiers, LIST_TYPE.options, activeModifier, onClickItem)}
					{renderDetails(composition, LIST_TYPE.ingredients, true)}
					{renderOthers(others)}
					{renderDetails(nutrition, LIST_TYPE.nutritional)}
					{renderDetails(energy, LIST_TYPE.energy)}
				</div>
			</div>
		</div>
	)
}, (((prevProps, nextProps) => prevProps.id === nextProps.id)));

export default DetailItem;