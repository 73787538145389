import {ROUT_LINKS} from "../constants";

/***
 * Replace route param to value
 * @param routeId
 * @param params
 */
export function updateRouteParamWithValue(routeId: string, params: {[key: string] : number | string}): string {
	let result = ROUT_LINKS[routeId];
	const keys = Object.keys(params);
	for (const key of keys) {
		result = result.replace(":" + key, "" + params[key]);
	}
	return result;
}
