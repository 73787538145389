import "./Button.styl";

import React, {FC} from "react";
import cn from "classnames";

import {ButtonPropsType} from "./types";

const Button: FC<ButtonPropsType> = ({component, className, type, primary, def, reversed, block, gray, outline, white, radius, size, loading, children, ...restProps}) => {
	const Component = component || "button",
		props = {
			...restProps,
			...(Component === "button" ? {type} : {}),
			className: cn("btn", {
				"btn-primary": primary,
				"btn-default": def,
				"btn-block": block,
				"btn-white": white,
				"btn-gray": gray,
				"border-radius": radius,
				[`btn-${size}`]: size,
				"btn--loading": loading,
				reversed,
				outline,
			}, className)
	};

	return (
		<Component {...props}>
			{!!loading && <i className="btn__loader"/>}
			{children}
		</Component>
	)
};

Button.defaultProps = {
	type: "button"
};

export default Button;

