import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';

import {CartItemProps} from "./types";
import Link from "../../UI/Link";
import {updateRouteParamWithValue} from "../../../services/RouteService";
import {ROUTE} from "../../../constants";
import {useCheckImageUrl} from "../../../utils";

import Icon from "../../UI/Icon/Icon";
import {ICONS} from "../../UI/Icon/enums";
import CartItemCounter from "../CartItemCounter/CartItemCounter";
import CartComment from "../CartComment/CartComment";

import './CartItem.styl';

function getModifiersList(modifiers) {
	if (modifiers.length === 0) {
		return ""
	} else {
		return ` (${modifiers.map(m => m.modifierName).join(", ")})`;
	}
}
const CartItem: FunctionComponent<CartItemProps> = ({item, filialId, tableId, removeLager, editCartLager}) => {

	const {id, img, price, name, unit, comment, count, modifiers, uid} = item,
		[commentVisibility, setCommentVisibility] = useState(false);
	const imgSrc = useCheckImageUrl(img);
	useEffect(() => {
		!!comment && setCommentVisibility(true)
	},[comment]);

	/****
	 * conver price to right format
	 * @param price
	 */
	const formattingPrice = useCallback((price) => {
		const formattedPrice = price.toString().split(".");
		return `${formattedPrice[0]}.${formattedPrice[1] || "00"} грн`;
	}, []);

	/***
	 * increment lager count
	 */
	const incrementCount = useCallback(() => {
		if (item.count >= 99) {
			item.count = 99;
		} else {
			item.count ++;
		}
		editCartLager(item)
	}, [item, editCartLager]);

	/***
	 * decrement lager count
	 */
	const decrementCount = useCallback(() => {
		const count = item.count - 1;
		if (count >= 99) {
			item.count = 99;
		} else if (!!count) {
			item.count = count;
			editCartLager(item);
		} else {
			removeLager(uid);
		}
	}, [item, uid, editCartLager, removeLager]);

	/***
	 *  lager count change
	 */
	const changeCount = useCallback((value) => {
		const count = parseInt(value);
		if (count >= 99) {
			item.count = 99;
			editCartLager(item);
		} else if (!!count) {
			item.count = count;
			editCartLager(item);
		}
	}, [item, editCartLager]);

	/***
	 *  Change comment
	 */
	const editComment = useCallback((comment) => {
		item.comment = comment;
		editCartLager(item);
	}, [item, editCartLager]);

	/***
	 *  Remove item
	 */
	const removeItem = useCallback(() => {
		removeLager(uid);
	}, [uid, removeLager]);

	return (
		<li className="cart-item">
			<div className="cart-item-wrapper">
				<div className="cart-item-image">
					<img src={imgSrc} alt={name} className="image"/>
				</div>
				<div className="cart-item-details">
					<div className="title-with-icon">
						<div className="cart-item-portion-title">
							<Link to={updateRouteParamWithValue(ROUTE.DETAIL, {
								filialId, tableId, id
							})}>
								<div className="cart-item-title">{name}{getModifiersList(modifiers)}</div>
							</Link>
							<div className="cart-item-weight">{unit}</div>
						</div>
						<Icon icon={ICONS.trashBucket} onClick={removeItem}/>
					</div>

					<div className="price-with-icon">

						<div className="cart-item-price">
							{formattingPrice(price)}
						</div>
						<Icon icon={ICONS.comments}
									className={commentVisibility ? "active" : null}
									onClick={() => setCommentVisibility(!commentVisibility)}/>
					</div>
				</div>
			</div>
			<div className="counter-with-total">
				<CartItemCounter
					incrementCount={incrementCount}
					decrementCount={decrementCount}
					changeCount={changeCount}
					count={count}
					countAll={true}
				/>
				<div className="cart-item-total-price">
					{formattingPrice(count * price)}
				</div>
			</div>
			{commentVisibility &&
			<CartComment
				comment={comment}
				editComment={editComment}
				maxLength={25}
				placeholder="Напишіть коментар"
			/>
			}
		</li>
	)
};


export default CartItem;