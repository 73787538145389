import React, {FC} from "react";
import {compose} from "recompose";

import {LagerType, LayoutPageProps} from "../../types";
import {DetailPageProps} from "./types";
import actions from "../../context/actions";

import WithContext from "../../components/HOCs/WithContext/WithContext";
import DetailItem from "../../components/DetailItem/DetailItem";
import RecommendedList from "../../components/RecommendedList/RecommendedList";

const Detail: FC<DetailPageProps> = (props) => {
	const {match, lagersLink, isLoading, onAdd} = props;
	const {id, filialId, tableId} = match.params;
	if (isLoading) {
		return null;
	}
	const lager = lagersLink[id];
	const recommendedIds = lager.recommended;
	const recommendedList: LagerType[] = [];
	for (const rId of recommendedIds) {
		const r = lagersLink[rId];
		if (!!r) {
			recommendedList.push(r)
		}
	}
	return (
		<>
			<DetailItem{...lager} onAdd={onAdd}/>
			<RecommendedList
				list={recommendedList}
				tableId={tableId}
				filialId={filialId}
				onAdd={onAdd}
			/>
		</>
	)
};

export default compose<DetailPageProps, LayoutPageProps>(
	WithContext(
		(state) => ({
			isLoading: state.isLoading,
			lagersLink: state.lagersLink
		}),
			dispatch => ({
				onAdd: (id: number|string) => {dispatch(actions.addLager(id))}
			})
	)
)(Detail)