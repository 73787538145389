import {LagerItem, MenuItem, ResMenu, OrderType} from "./types";
import {
	CartItemType,
	CartLinkItemType,
	CartType,
	CategoryType,
	InitDataType,
	LagersLinkType,
	LagerType, ModifiersType
} from "../types";

/***
 * Parse JSON from the server;
 * @param data
 */
export function parseMenuJSON(data: ResMenu | string): InitDataType {
	const {filialid, orderid, items} = typeof data === "string"? JSON.parse(data) : data;
	const initData: InitDataType = {
		isLoading: false,
		filialId: filialid,
		orderId: orderid,
		lagers: [],
		lagersLink: {},
		categories: [],
		categoriesLink: {}
	};

	for (const item of items) {
		parseCategory(item, initData);
	}
	return initData;
}

/***
 * Parse category
 * @param category
 * @param initData
 * @param parent
 */
function parseCategory(category: MenuItem, initData: InitDataType, parent?: CategoryType) {
	const {id, name, menus, lagers} = category;
	const c: CategoryType = {
		id,
		name,
		items: [],
		lagers: [],
	};
	for (const item of menus) {
		parseCategory(item, initData, c);
	}
	for (const lager of lagers) {
		const l = parseLager(lager);
		c.lagers.push(l);
		initData.lagers.push(l);
		initData.lagersLink[l.id] = l;
	}
	if (!!parent && parent.items) {
		parent.items.push(c);
	}
	initData.categoriesLink[id] = c;
	!parent && initData.categories.push(c);
}

/***
 * Parse lager items
 * @param lager
 */
function parseLager(lager: LagerItem) {
	const result: LagerType = {
		id: lager.id,
		name: lager.name || "",
		cookingTime: lager.cookingTime || "",
		img: lager.photopath || "",
		unit: lager.unit || "",
		price: lager.price || 0,
		modifiers: lager.modifiers || [],
		recommended: lager.recommended || [],
		compositionTitle: "",
		composition: [],
		nutrition: [],
		energy: [],
		others: []
	};

	for (const add of lager.adds) {
		switch (add.id) {
			case 47:
				result.composition = (add.value || "").replace(", ", ",").split(",");
				result.compositionTitle = add.name || "";
				break;
			case 124:
			case 125:
			case 126:
				result.nutrition.push(`${add.name || ""}: ${add.value || ""}г`);
				break;
			case 127:
				const [kilocalories, kilojoules] = (add.value || "").split("/");
				result.energy.push(`${kilocalories || ""}кКал`);
				result.energy.push(`${kilojoules || ""}кДж`);
				break;
			default:
				result.others.push({
					title: add.name,
					value: add.value
				});
		}
	}
	return result
}

/***
 * Save cart to storage
 * @param cart
 */
export function saveCart(cart: CartType): CartType {
	localStorage.setItem("online-cart", JSON.stringify(cart));
	return cart;
}

/***
 * Remove all staff in cart and return clear cart
 */
export function removeCart(): CartType {
	localStorage.removeItem("online-cart");
	return {
		count: 0,
		items: {},
		comments: "",
		totalSum: 0
	};
}

/***
 * Add single lager to cart
 * @param lagerId
 * @param cart
 * @param lagersLink
 */
export function addLagerToCart(lagerId: number | string, cart: CartType, lagersLink: LagersLinkType): CartType {
	const {items, comments} = cart;
	let l;
	const modifiers: ModifiersType[] = [];
	if (typeof lagerId === "number") {
		l = lagersLink[lagerId];
	} else {
		let [id, ...modifierNumbers] = lagerId.split("|");
		l = lagersLink[id];
		for (const modifier of (l || {}).modifiers || []) {
			for (const modifierId of modifierNumbers) {
				if (modifier.modifierId === +modifierId) {
					modifiers.push(modifier);
				}
			}
		}
	}

	if (!!items[lagerId]) {
		if (items[lagerId].count >= 99) {
			items[lagerId].count = 99;
		} else {
			items[lagerId].count += 1;
		}
	} else if (!!l) {
		items[lagerId] = {
			uid: lagerId,
			count: 1,
			comment: "",
			id: l.id,
			name: l.name,
			img: l.img,
			unit: l.unit,
			price: l.price,
			modifiers
		};
	}
	return {
		...countAndSum(items),
		comments
	}
}

/***
 * Remove single lager from cart
 * @param lagerId
 * @param cart
 */
export function removeCartLager(lagerId: number | string, cart: CartType): CartType {
	const {items} = cart;
	delete items[lagerId];
	const isLastLager = Object.values(items).length === 0;
	const comments = !isLastLager ? cart.comments : "";
	return {
		...countAndSum(items),
		comments
	}
}

/***
 * Count total sum and count items in cart
 * @param items
 */
export function countAndSum(items: CartLinkItemType): { items: CartLinkItemType, count: number, totalSum: number } {
	let count = 0;
	let totalSum = 0;
	const cartItems = Object.values(items);
	for (const ci of cartItems) {
		count += ci.count;
		totalSum += ((ci.price * 100) * ci.count) / 100
	}
	return {
		items: {...items},
		count,
		totalSum
	}
}

/***
 * Edit single lager comment
 * @param payload
 * @param cart
 */
export function editLagerComment(payload: { lagerId: number, comment: string }, cart: CartType): CartType {
	const {lagerId, comment} = payload;
	const {items} = cart;
	if (!!items[lagerId]) {
		items[lagerId].comment = comment;
	}
	return {
		...cart,
		items: {...items},
	}
}

/***
 * Edit single lager count
 * @param cartItem
 * @param cart
 */
export function editCartLager(cartItem: CartItemType, cart: CartType): CartType {
	const {items, comments} = cart;
	items[cartItem.uid] = cartItem;

	return {
		...countAndSum(items),
		comments
	};
}

/***
 * Edit cart comment
 * @param comments
 * @param cart
 */
export function editCartComment(comments: string, cart: CartType): CartType {
	return {
		...cart,
		comments,
	}
}

/***
 * Load cart from storage
 */
export function loadCart(): CartType {
	let cart = {
		count: 0,
		items: {},
		comments: "",
		totalSum: 0
	};
	let fromStore = localStorage.getItem("online-cart");
	if (!!fromStore) {
		cart = JSON.parse(fromStore);
	}
	return cart;
}

/***
 * Convert store to specific order type for sending to api
 * @param filialId
 * @param tableId
 * @param orderId
 * @param cart
 * @param phone
 */
export function createOrder(filialId: number, tableId: number, orderId: number, cart: CartType, phone: string): OrderType[] {
	const {items, comments} = cart;
	const order: OrderType = {
		id: orderId,
		filialid: filialId,
		tableid: tableId,
		comment: comments,
		phone: phone,
		list: []
	};

	const cartItems = Object.values(items);
	for (const ci of cartItems) {
		order.list.push({
			lagerid: ci.id,
			count: ci.count,
			comment: ci.comment,
			modifiers: ci.modifiers
		})
	}

	return [order];
}