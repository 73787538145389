import React, {FC} from "react";
import {Link as RRLink} from "react-router-dom";

import {isObject, isString} from "../../utils";

function isExternalLink(link) {
	return !!(isObject(link) && link.host)||(isString(link) && link.indexOf("http") !== -1);
}


type LinkPropsType = {
	to: string | {[key: string] : string} | any;
	className?: string;
}

const Link:FC<LinkPropsType> = ({to, ...restProps}) => {
	const isExternal = isExternalLink(to);
	if (isExternal) {
		// eslint-disable-next-line
		return <a {...{href: to, ...restProps}}/>
	} else {
		return <RRLink {...{to}} {...restProps}/>
	}
};


export default Link;