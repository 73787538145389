import React, {FunctionComponent, useCallback} from 'react';
import {CartItemCounterProps} from "./types";
import './CartItemCounter.styl';

/***
 * handle focus
 * @param event
 */
function handleFocus(event){
	event.target.focus();
	event.target.select();
}

const CartItemCounter: FunctionComponent<CartItemCounterProps> = ({incrementCount, decrementCount, count, changeCount, countAll = false}) => {

	const onCountChange = useCallback((e) => {
			changeCount(e.target.value)
	}, [changeCount]);

	return (
		<div className="input-counter">
			<div className="input-counter__increment decrement"
					 onClick={decrementCount}
			> -
			</div>
			<div className="input-counter__count-value">
				<input
					type="text"
					className="input-counter__count-input"
					value={count}
					onChange={(e)=>onCountChange(e)}
					onClick={handleFocus}
				/>
			</div>
			<div className="input-counter__increment"
					 onClick={incrementCount}
			> +
			</div>
		</div>
	)
};


export default CartItemCounter;