import React, {FunctionComponent} from "react";

import {ICONS} from "../UI/Icon/enums";
import Icon from "../UI/Icon/Icon";

import "./Modal.styl"

interface ModalProps {
	showModal: boolean;
	closeModal: ()=>void;
}

const Modal: FunctionComponent<ModalProps> = (props) => {
	const { showModal, closeModal, children } = props;
	const showHideClassName = showModal ? "modal show" : "modal hide";
	return (
		<div className={showHideClassName}>
			<div className="modal-main">
				<Icon icon={ICONS.cross}
							onClick={closeModal}
							className="close-button"/>
				{children}
			</div>
		</div>
	);
};

export default Modal;