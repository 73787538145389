import React, {FC, memo, useCallback, useRef} from 'react';

import {ListItemProps} from "./types";
import {useCheckImageUrl} from "../../utils";
import {ROUTE} from "../../constants";
import {updateRouteParamWithValue} from "../../services/RouteService";

import Link from "../UI/Link";

import './ListItem.styl';

/***
 * returns node of price separated by "."
 * @param price
 */
function renderPortionPrice(price){
	const formattedPrice = price.toString().split(".");
	return(
		<div className="portion-price">
			<div className="portion-price-integer">{formattedPrice[0]}</div>
			<div className="portion-price-details">
				<div className="portion-price-fraction">{formattedPrice[1] || "00"}</div>
				<div className="portion-price-currency">грн</div>
			</div>
		</div>
	)
}

const ListItem: FC<ListItemProps>  = memo((props) => {

	const {img, price, name, unit, filialId, tableId, id, onAdd} = props;
	const imgSrc = useCheckImageUrl(img);
	const productRef = useRef<HTMLImageElement>(null);
	// const {flyingImage, animatedAddToCart} = FlyToCart(imgSrc, productRef);
	const onClick = useCallback(() => {
		onAdd(id);
		// animatedAddToCart();
	}, [onAdd, id]);

	return (
			<li className="list-item-wrapper">
				{/*{flyingImage}*/}
				<Link to={updateRouteParamWithValue(ROUTE.DETAIL, {
					filialId, tableId, id})}>
					<img src={imgSrc} alt={name} className ="image"/>
				</Link>
				<div className="cont">
					<Link to={updateRouteParamWithValue(ROUTE.DETAIL, {
						filialId, tableId, id})}>
						<div className="title-text">{name}</div>
						<div className="weight">{unit}</div>
					</Link>
					<div className="info">
						{renderPortionPrice(price)}
					</div>
					<div className="basket-button" onClick={onClick}  ref={productRef}> В КОШИК</div>
				</div>
			</li>
		)
}, (((prevProps, nextProps) => {
	return prevProps.id === nextProps.id
})));


export default ListItem;