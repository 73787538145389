import React, {FC, useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router";
import {compose} from "recompose";
import cn from "classnames";

import {MenuCatalogProps, MenuCatalogOuterProps} from "./types";
import {CategoryType} from "../../../types";

import {ICONS} from "../../UI/Icon/enums";
import {scrollToTop} from "../../../utils";
import {ROUTE} from "../../../constants";
import WithContext from "../../HOCs/WithContext/WithContext";
import {updateRouteParamWithValue} from "../../../services/RouteService";

import Icon from "../../UI/Icon/Icon";
import Link from "../../UI/Link";

import "./MenuCatalog.styl";


const MenuCatalog: FC<MenuCatalogProps> = (props) => {
	const {categories, isOpen, onClose} = props;
	const {filialId="", tableId="", categoryId=""} = useParams();
	const [activeParent, setActiveParent] = useState<number>(categoryId ? +categoryId : 0);

	/***
	 * initialize activeParent
	 */
	useEffect(() => {
		categories.forEach(({id,items}) => {
			if(id === activeParent) {return}
			items.forEach((item) => {
				if(item.id === activeParent) {return setActiveParent(id)}
			})
		})
		// eslint-disable-next-line
	},[categories]);

	const toggleActiveParent = useCallback((id) => {
		setActiveParent(prevState => prevState === id ? 0 : id)
	}, []);

	const updateActiveParent = useCallback((id) => {
		scrollToTop();
		onClose();
		toggleActiveParent(id)
	}, [onClose, toggleActiveParent]);

	const renderSubCatalogItems = useCallback((subCategories, parentId) => {
		return(
			<ul className="sub-catalog">
				{subCategories.map((subCategory: CategoryType) => {
					const {id, name} = subCategory;
					const isActive = +categoryId === id;

					return (
						<li key={id} onClick={() => {updateActiveParent(parentId)}} className={cn("sub-category-item", {"active" : isActive})}>
							<Link to={updateRouteParamWithValue(ROUTE.CATEGORY, {filialId, tableId, categoryId: id})}>
								{name}
							</Link>
						</li>
					)
				})}
			</ul>
		);
	},[updateActiveParent, filialId, tableId, categoryId]);

	const renderCatalogItems = useCallback(() => {
		return(
			<ul className="catalog">
				{categories.map((category: CategoryType)=>{
					const {id, items, name} = category;
					const isActiveCatalog = activeParent === id;
					const isActive = +categoryId === id;

					if (items.length > 0) {
						return (
							<li key={id} onClick={() => {toggleActiveParent(id)}} className={cn("category-item", {"active" : isActiveCatalog})}>
								<div className="category-item-title">
									<div>{name}</div>
									<Icon icon={ICONS.chevronDown}/>
								</div>
								{renderSubCatalogItems(items, id)}
							</li>
						)
					} else {
						return (
							<li key={id} onClick={() => {updateActiveParent(id)}} className={cn("category-item", {"active" : isActive})}>
								<Link to={updateRouteParamWithValue(ROUTE.CATEGORY, {filialId, tableId, categoryId: id})}>
									{name}
								</Link>
							</li>
						)
					}
				})}
			</ul>
		);
	},[categories, updateActiveParent, activeParent, toggleActiveParent, filialId, tableId, categoryId, renderSubCatalogItems]);


	return (
		<div className={cn("menu-catalog", {"open": isOpen})}>
			{renderCatalogItems()}
		</div>
	);
};


export default compose<MenuCatalogProps, MenuCatalogOuterProps>(
	WithContext(
		(state) => ({	categories: state.categories})
	)
)(MenuCatalog);