import React, {FC} from "react";

import LogoSvg from "../../components/LogoSvg/LogoSvg";

const Unavailable: FC = () => {
	return (
		<div className="error-page-wrapper">
			<div className="error-page-holder">
				<LogoSvg />
				<div className="error-code">500</div>
				<div className="error-text">
					Вибачте, сайт зараз не працює. Ми знаємо про проблему
					і вирішуємо її. Будь ласка, повертайтеся пізніше.
				</div>
			</div>
		</div>
	)
};

export default Unavailable