import React, {FC} from "react";

import {RecommendedListType} from "./type";

import ListItem from "../ListItem/ListItem";

import "./RecommendedList.styl";

const RecommendedList: FC<RecommendedListType> = (props) => {
	const {list, onAdd, filialId, tableId} = props;
	if (!list || list.length === 0) {
		return null
	}
	return (
		<div className="recommended-wrapper">
			<h3>Рекомендовані товари</h3>
			<div  className="recommended-list-holder">
				<ul className="recommended-list">
					{list.map(item => (
						<ListItem {...item} onAdd={onAdd} filialId={filialId} tableId={tableId} key={item.id} />
					))}
				</ul>
			</div>
		</div>
	)
};

export default RecommendedList;