import React, {FC} from "react";
import {compose} from "recompose";
import {withRouter} from "react-router";

import Modal from "../../Modal";
import WithContext from "../../../HOCs/WithContext/WithContext";
import {ROUTE} from "../../../../constants";

type ModalProps = {
	showModal: boolean;
	closeModal: ()=>void;
	order: number;
	filial: number;
	table: string;
	history: any;

}
type ModalOutProps = {
	showModal: boolean;
	closeModal: ()=>void;
}



const SuccessModal: FC<ModalProps> = (props)=> {
		const{showModal, closeModal, order, history, filial, table} = props;

		const close = () => {
			closeModal();
			history.push(`/${ROUTE.HOME}/${filial}/${table}`);
		};
		return (
			<>
				<Modal showModal={showModal} closeModal={close}>
					<h1 className="title">Вітаємо!</h1>
					<p className="description" >Ваше замовлення №{order} успішно відправлене, очікуйте на офіціанта</p>
				</Modal>

			</>
		)
};

export default compose<ModalProps, ModalOutProps>(
	withRouter,
	WithContext(
		(state) => ({
			filial: state.filialId,
			table: state.location.tableId,
			order: state.orderId
		})
	)
)(SuccessModal);

// const [showModal, setModalVisability] = useState(false);
//<div  onClick={()=>{setModalVisability(true)}}>open</div>
// 		<SuccessModal showModal = {showModal} closeModal = {()=>{setModalVisability( false)}}  />