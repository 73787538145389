import React, {FC} from "react";

import cn from "classnames";
import {LinkAllProps} from "./types";
import Link from "../Link";

const LinkAll : FC<LinkAllProps> =  ({children,  to="", className, ...props}) => {
	return (
		<Link to={to} className={cn("link-all", className)} {...props}>
			<span className="link-all__content">{children}</span>
		</Link>
	)
};

export default LinkAll;
