import React, {FC, useCallback, useState} from 'react';
import cn from "classnames";

import {MenuProps} from "./types";

import {ICONS} from "../../UI/Icon/enums";

import Icon from "../../UI/Icon/Icon";
import Button from "../../UI/Button/Button";
import MenuCatalog from "../MenuCatalog/MenuCatalog";

import "./Menu.styl";


const Menu: FC<MenuProps> = (props) => {
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const toggleMenuCatalog = useCallback(() => {
		setShowMenu(prevState => !prevState);
	},[]);
	const closeMenuCatalog = useCallback(() => {
		setShowMenu(false);
	},[]);


	return (
		<div className="menu">
			<Button className={cn("menu-btn", {"active": showMenu})} primary onClick={toggleMenuCatalog}>
				<span>Меню</span>
				<Icon icon={ICONS.chevronDown}/>
			</Button>

			<MenuCatalog isOpen={showMenu} onClose={closeMenuCatalog}/>
		</div>
	);
};

export default Menu;