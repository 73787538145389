import React from "react";

import reducer from "../context/reducers";
import {Store, initialState} from "../context/context";

export function StoreProvider(props) {
	const [state, dispatch] = React.useReducer(reducer, initialState);
	const value = {state, dispatch};

	return (
		<Store.Provider value={value}>
			{props.children}
		</Store.Provider>
	)
}