import React, {FunctionComponent} from 'react';

import {CartListProps} from "./types";
import CartItem from "../CartItem/CartItem";

import './CartList.styl';


const CartList: FunctionComponent<CartListProps> = ({list, tableId, filialId, removeLager, editCartLager
}) => {
	return (
		<ul className="cart-list">
			{list.map(item =>
				<CartItem
					key={item.id}
					item={item}
					filialId={filialId}
					tableId={tableId}
					removeLager={removeLager}
					editCartLager={editCartLager}
				/>)}
		</ul>
	)
};


export default CartList;