import React, {FC} from "react";
import {compose} from "recompose";

import {LayoutPageProps} from "../../types";
import {HomePageProps} from "./types"

import actions from "../../context/actions";
import WithContext from "../../components/HOCs/WithContext/WithContext";

import Intro from "../../components/Intro/Intro";
import ListItem from "../../components/ListItem/ListItem";

const INTRO_TEXTS = {
	title: "Вітаємо!",
	description:"Не витрачайте час! Самостійно створюйте замовлення онлайн, та очікуйте за цим столиком офіціанта з готовими стравами."
};

const Home: FC<HomePageProps> = (props) => {
	const {match, lagers, isLoading, onAdd} = props;
	const {filialId, tableId} = match.params;
	if (isLoading) {
		return null;
	}

	return(
		<>
			<Intro  description={INTRO_TEXTS.description} title={INTRO_TEXTS.title} />
			<h2>Всі страви</h2>
			<ul className="categories">
				{lagers.map(item => (
					<ListItem {...item} onAdd={onAdd} filialId={filialId} tableId={tableId} key={item.id} />
				))}

			</ul>
		</>
		)
};

export default compose<HomePageProps, LayoutPageProps>(
	WithContext(
		(state) => ({
			isLoading: state.isLoading,
			lagers: state.lagers
		}),
		dispatch => ({
			onAdd: (id) => {dispatch(actions.addLager(id))}
		})
	)
)(Home);