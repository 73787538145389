import React from 'react'
import {loadCart} from "../services/AssistanceService";

export const initialState = {
	isLoading: true,
	location: {
		filialName: "",
		tableId: ""
	},
	filialId: 0,
	orderId: 0,
	lagers: [],
	lagersLink: {},
	categories: [],
	categoriesLink: {},
	cart: loadCart()
};

export const Store = React.createContext(initialState as {});