import React, {FunctionComponent, useState, useCallback} from "react";
import {useHistory, withRouter} from "react-router";
import {compose} from "recompose";

import {LayoutPageProps} from "../../types";
import {CartPageType} from "./types"

import {sendOrder} from "../../services/ApiService";
import {updateRouteParamWithValue} from "../../services/RouteService";
import actions from "../../context/actions";
import {ROUTE} from "../../constants";

import WithContext from "../../components/HOCs/WithContext/WithContext";
import CartList from "../../components/Cart/CartList/CartList";
import CartTotal from "../../components/Cart/CartTotal/CartTotal";
import CartComment from "../../components/Cart/CartComment/CartComment";
import SuccessModal from "../../components/Modal/Modals/SuccessModal/SuccessModal";
import Modal from "../../components/Modal/Modal";
import Link from "../../components/UI/Link";
import Button from "../../components/UI/Button/Button";
import PhoneInput from "../../components/PhoneInput/PhoneInput";

import "./Cart.styl"

const Cart: FunctionComponent<CartPageType> = (props) => {
	const {cart, orderId, tableId, filialId, isLoading} = props;
	const {clearCart, editCartComment, removeCartLager, editCartLager, updateLocation} = props;
	const {items, totalSum, comments} = cart;
	const history = useHistory();

	const [showModal, setModalVisibility] = useState(false);
	const [{phone}, phoneUpdate] = useState<{phone: string, isValid: boolean}>({phone: "", isValid: false});

	const list = Object.values(items);
	const isEmptyCart = list.length === 0;
	/***
	 * Create order temp version
	 */
	const createOrder = useCallback(() => {
		sendOrder(filialId, tableId, orderId, cart, phone)
			.then(result => {
				if (!!result) {
					clearCart();
					setModalVisibility(true);
				} else {
					throw new Error("No data");
				}
			})
			.catch(e => {
				console.error(e);
			})
	}, [filialId, tableId, orderId, cart, phone, clearCart]);

	/***
	 * Close warning modal
	 */
	const closeModal = useCallback(() => {
		history.replace(`/${ROUTE.HOME}/${filialId}/${tableId}`);
	}, [filialId, history, tableId]);

	/***
	 *  Close success modal
	 */
	const closeSuccessModal = useCallback(() => {
		updateLocation(filialId, tableId);
		setModalVisibility(false);
	}, [ filialId, tableId, updateLocation]);

	if (isLoading) {
		return null;
	}

	return (
		<div className="cart-container">
			<CartList list={list}
								tableId={tableId}
								filialId={filialId}
								removeLager={removeCartLager}
								editCartLager={editCartLager}
			/>

			{!isEmptyCart && <>
				<CartComment
          maxLength={30}
          comment={comments}
          editComment={editCartComment}
          placeholder="Введіть коментар"
      />
				<PhoneInput phoneUpdate={phoneUpdate} phone={phone}/>
      </>}

			<CartTotal total={totalSum} createOrder={createOrder} readyToSubmit={true}/>

			<SuccessModal showModal={showModal} closeModal={closeSuccessModal}/>

			<Modal showModal={!showModal && isEmptyCart} closeModal={closeModal}>
				<h1 className="title">Ваш кошик порожній!</h1>
				<div className="empty-cart-modal">
					<p className="description">
						Додайте в кошик страву з меню для оформлення замовлення!
					</p>
					<Link to={updateRouteParamWithValue(ROUTE.HOME, {filialId, tableId})}>
						<Button primary>Перейти до меню</Button>
					</Link>
				</div>
			</Modal>

		</div>
	)
};

export default compose<CartPageType, LayoutPageProps>(
	withRouter,
	WithContext(
		(state) => ({
			isLoading: state.isLoading,
			filialId: state.filialId,
			tableId: state.location.tableId,
			orderId: state.orderId,
			lagersLink: state.lagersLink,
			cart: state.cart
		}),
		dispatch => ({
			clearCart: () => dispatch(actions.clearCart()),
			editCartLager: (cartItem) => dispatch(actions.editCartLager(cartItem)),
			editCartComment: (comment) => dispatch(actions.editCartComment(comment)),
			removeCartLager: (lagerId) => dispatch(actions.removeCartLager(lagerId)),
			editCartLagerComment: (comment, lagerId) => dispatch(actions.editCartLagerComment({lagerId, comment})),
			updateLocation: (filialId, tableId) => dispatch(actions.setLocation(filialId, tableId, dispatch)),
		})
	)
)(Cart)