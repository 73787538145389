import React, {FC} from "react";
import {useLocation} from "react-router";
import {compose} from "recompose";

import {LayoutPageProps, LagerType} from "../../types";
import {SearchPageProps} from "./types";

import actions from "../../context/actions";
import WithContext from "../../components/HOCs/WithContext/WithContext";
import {getQueryParam, getCorrectEnding} from "../../utils";

import Loader from "../../components/UI/Loader/Loader";
import NoData from "../../components/UI/NoData/NoData";
import ListItem from "../../components/ListItem/ListItem";

import "./Search.styl";


function searchLagers(lagers: LagerType[], searchValue: string): LagerType[] {
	return lagers.filter(({name}) => name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
}
const CORRECT_ENDING_OPTIONS: {one: string; two: string; few: string} = {
	one: "результат",
	two: "результати",
	few: "результатів"
};

const Search: FC<SearchPageProps> = (props) => {
	const {match, lagers, isLoading, onAdd} = props;
	const {filialId, tableId} = match.params;

	const {search} = useLocation();
	const find = getQueryParam("find", search);

	let searchResult: Array<LagerType> = [];
	let searchStatusText: string = "";
	if (!isLoading && find.length > 2 && lagers.length) {
		searchResult = searchLagers(lagers, find);
		searchStatusText = getCorrectEnding(searchResult.length, CORRECT_ENDING_OPTIONS)
	}

	return (
		<div className="search-page">
			{isLoading
				? <Loader/>
				: searchResult.length
					? <>
							<div className="search-status">
								За запитом <b>{find}</b> знайдено <br/> <b>{searchResult.length}</b> {searchStatusText}
							</div>
							<ul className="categories">
								{searchResult.map(item => (
									<ListItem {...item} onAdd={onAdd} filialId={filialId} tableId={tableId} key={item.id} />
								))}
							</ul>
						</>
					: <NoData>
					  	На жаль, за такимим запитом товарів не знайдено
						</NoData>
			}
		</div>
	)
};


export  default compose<SearchPageProps, LayoutPageProps>(
	WithContext(
		(state) => ({
			isLoading: state.isLoading,
			lagers: state.lagers
		}),
		dispatch => ({
			onAdd: (id) => {dispatch(actions.addLager(id))}
		})
	)
)(Search);